import React, { useEffect } from "react";
import { Typography, Grid, TextField } from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";
import { getAddressFromCoordinates } from "../../services/geocodingService";
import { getUserLocationCoordinates } from "../../services/customHooks/useBrowserGeolocationService";

import { innerContainerStyle, innerItemStyle } from "../../styles/containerStyles";

const API_KEY = process.env.REACT_APP_GOOGLE_SERVICES_API_KEY;
const FREE_TEXT_MAX_CHARACTER_LENGTH = 200;

const UserPreferencesSection = ({
  userLocation,
  setUserLocation,
  foodPreferences,
  setFoodPreferences,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });

  const handleFoodPreferencesChange = (event) => {
    setFoodPreferences(event.target.value);
  };

  useEffect(() => {
    if (isLoaded) {
      getUserLocationCoordinates()
        .then(async ({ latitude, longitude }) => {
          // Get the city, state, and country from coordinates
          const { city, state, country } = await getAddressFromCoordinates(latitude, longitude);

          // Set user location with coordinates and address information
          setUserLocation({ city, state, country, latitude, longitude });
        })
        .catch((error) => {
          console.error("Error getting location:", error);
        });
    }
  }, [isLoaded, setUserLocation]);

  return (
    <Grid container item xs={12} style={innerContainerStyle}>
      <Grid item xs={12} style={innerItemStyle}>
        <Typography variant="h5">User Preferences</Typography>
      </Grid>
      <Grid item xs={12} style={innerItemStyle}>
        {/* Display user's location or information about fetching location */}
        {userLocation ? (
          <Typography variant="body1">
            {userLocation.city && userLocation.state && userLocation.country
              ? `Your location: ${userLocation.city}, ${userLocation.state}, ${userLocation.country}`
              : "We'd like to suggest restaurants near you. Use the Geolocation option below."}
          </Typography>
        ) : (
          <Typography variant="body1">
            We'd like to suggest restaurants near you. Use the Geolocation option below.
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} style={innerItemStyle}>
        <TextField
          id="food-preferences"
          label="Describe your dining preferences"
          multiline
          rows={3}
          variant="outlined"
          value={foodPreferences}
          onChange={handleFoodPreferencesChange}
          fullWidth
          inputProps={{
            maxLength: FREE_TEXT_MAX_CHARACTER_LENGTH, // Set the maximum number of characters
          }}
        />
        <Typography variant="body2" color="textSecondary" align="right">
          {foodPreferences.length}/{FREE_TEXT_MAX_CHARACTER_LENGTH} characters
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UserPreferencesSection;
