import { ApiError } from "../../errors/customErrors";

const getUserLocationCoordinates = async () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error) => {
          reject(error);
        }
      );
    } else {
      reject(new ApiError("Geolocation is not available"));
    }
  });
};

export { getUserLocationCoordinates };
