// ----------- STYLING GUIDE ----------- //

// 60-30-10 rule
const colors = {
  primary: "#1976d2", // same Blue as mui primary color
  primaryText: "white",
  secondaryText: "black",
  specialText: "",
  dominant: "#F5F5F5", // Light Grey
  secondary: "#B19CD9", // Light Purple
  accent: "#8A4D76", // Purple (Accent 1)
  accent2: "#77dd77", // Green (Accent 2)
};

const backgroundStyle = {
  background: "linear-gradient(to bottom, white,  lightblue, white)",
  minHeight: "100vh",
  minWidth: "100vw",
};

const backgroundStyle2 = {
  background: "linear-gradient(to bottom, white, 	#FFC0CB, white)",
  minHeight: "100vh",
  minWidth: "100vw",
};

export { colors, backgroundStyle, backgroundStyle2 };
