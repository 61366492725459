import React, { useState } from "react";
import {
  Container,
  Box,
  Stack,
  Chip,
  ListItem,
  styled,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";

const DecisionPage = () => {
  const { id } = useParams();
  const [restaurantName, setRestaurantName] = useState(""); // initial value of state var restaurantName is ''
  const [tags, setTags] = useState([]);
  const [decision, setDecision] = useState("");
  const [colorMap, setColorMap] = useState({}); //restaurant:color mapping

  const tagBoxStyle = {
    // the big box
    display: "flex", // tags display in row
    flexWrap: "wrap",
    marginTop: "35px",
    marginBottom: "35px",
    border: "1px solid #ccc",
    borderRadius: "16px",
    padding: "15px",
    minHeight: "200px", // Minimum height to maintain the tag size
    alignItems: "flex-start",
    listStyle: "none", // remove the bullet dots
  };

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const handleInputChange = (e) => {
    setRestaurantName(e.target.value);
  };

  const getRandomLightColor = () => {
    // Generate random values for red, green, and blue components
    // by restricting the range between 128-255, generated colors will have higher values -> lighter color
    const red = Math.floor(Math.random() * 128) + 128; // Random value between 128 and 255
    const green = Math.floor(Math.random() * 128) + 128; // Random value between 128 and 255
    const blue = Math.floor(Math.random() * 128) + 128; // Random value between 128 and 255

    // Convert the RGB values to hexadecimal format
    const color = `#${red.toString(16).padStart(2, "0")}${green.toString(16).padStart(2, "0")}${blue
      .toString(16)
      .padStart(2, "0")}`;

    return color;
  };

  // Function to handle form submission
  const handleAdd = (e) => {
    e.preventDefault();
    // logic to handle the restaurant name submission goes here

    const trimmedRestaurantName = restaurantName.trim();

    if (tags.includes(trimmedRestaurantName)) {
      console.log("Restaurant already added!");
      const existingChip = document.querySelector(`[data-tag="${trimmedRestaurantName}"]`);
      if (existingChip) {
        existingChip.classList.add("shake");
        setTimeout(() => {
          existingChip.classList.remove("shake");
        }, 300); // remove shake after 200 mili sec, otherwise will only shake once
      }
      return;
    }
    if (trimmedRestaurantName === "") {
      console.log("Empty restaurant name not added");
      return;
    }
    if (!colorMap[trimmedRestaurantName]) {
      const randomColor = getRandomLightColor();
      setColorMap((prevColorMap) => ({
        ...prevColorMap,
        [trimmedRestaurantName]: randomColor,
      })); // callback ... shallow copies previous colorMap state, then add new key val pair, return to setColorMap
    }

    setTags((prevTags) => [...prevTags, trimmedRestaurantName]);
    setRestaurantName(""); // clear input box after add

    console.log("Added restaurant name:", restaurantName);
    console.log(tags);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAdd(e);
    }
  };

  const handleDelete = (tagToDelete) => () => {
    // TODO: use index instead of comparing tag name string?
    setTags((tags) => tags.filter((tag) => tag !== tagToDelete));
    console.log(tags);
  };

  const handleClearAll = () => {
    setTags([]);
    setColorMap({});
    setDecision("");
  };

  const handleDecide = () => {
    if (tags.length == 0) {
      console.log("No options provided");
      return;
    }
    const randomIndex = Math.floor(Math.random() * tags.length);
    setDecision(tags[randomIndex]);
  };

  return (
    <Box m="0 auto" maxWidth="md">
      <Stack alignItems="center">
        <h3>Room id: {id} </h3>
        <h2>Basic Mode</h2>
      </Stack>

      <h4 style={{ fontWeight: "normal", fontSize: "16px" }}>
        Which restaurants are you feeling today?
      </h4>

      <Stack direction="row" spacing={2} marginLeft={1}>
        <TextField
          id="restaurantName"
          value={restaurantName}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress} // Add this line
          label="Add a restaurant.."
          variant="outlined"
        />
        <Button variant="contained" size="medium" onClick={handleAdd}>
          Add
        </Button>
      </Stack>

      <Container maxWidth="md" style={tagBoxStyle}>
        {tags.map((tag, index) => (
          <ListItem key={index}>
            <Chip
              label={tag}
              variant="outlined"
              size="medium"
              style={{ backgroundColor: colorMap[tag] }}
              onDelete={handleDelete(tag)}
              data-tag={tag} //data attribute, use to find existing tag
            />
          </ListItem>
        ))}
      </Container>

      <Box display="flex" justifyContent="flex-end" marginRight="10px">
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            size="large"
            onClick={handleClearAll}
            disabled={tags.length === 0}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleDecide}
            disabled={tags.length == 0}
          >
            Decide
          </Button>
        </Stack>
      </Box>

      <Stack direction="row" alignItems="center" spacing={2} marginTop={4}>
        <h4 style={{ fontWeight: "normal", fontSize: "18px" }}>
          Decision maker suggests you to go to
        </h4>
        <TextField
          id="decisionRestaurant"
          InputProps={{
            readOnly: true,
            startAdornment: (
              <Typography
                variant="h6"
                style={{
                  fontSize: "20px",
                  color: colorMap[decision],
                  fontWeight: "bold",
                }}
              >
                {decision}
              </Typography>
            ),
            style: {
              whiteSpace: "nowrap",
              width: "200px",
            },
          }}
          variant="standard"
          color="primary"
        />
      </Stack>
    </Box>
  );
};

export default DecisionPage;
