const sectionContainerStyle = {
  paddingTop: "100px",
  display: "flex",
  minHeight: "90vh",
  width: "80%",
};

const sectionHeadingStyle = {
  fontWeight: "bold",
  marginTop: "3%",
  marginBottom: "3%",
  fontSize: "48px",
};

export { sectionContainerStyle, sectionHeadingStyle };
