import React from "react";
import { Grid, Typography, Container, Box } from "@mui/material";
import { sectionContainerStyle, sectionHeadingStyle } from "../../styles/hompageStyles.js";
import { backgroundStyle } from "../../styles/_defaults.js";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GoalCard from "./GoalCard";

const AboutContent =
  "During the undergrad years, away from the comforts of home, I relied heavily on dining out. However, deciding what to eat became a daily challenge. Hours were spent scrolling through endless options on food delivery apps. As a newcomer to a city, the task of discovering good food seemed overwhelming. This dilemma extended to group settings, how do you efficiently decide on a meal that everyone will enjoy? These experiences sparked the idea for our app: to simplify dining choices and make them not only easy but also delightful.";

const AboutSection = () => {
  return (
    <Box id="about-section" style={backgroundStyle}>
      <Container maxWidth={false} style={sectionContainerStyle}>
        <Grid container sx={{ mx: "auto", maxWidth: "1200px" }}>
          <Grid item xs={12}>
            <Typography variant="h4" style={{ ...sectionHeadingStyle }}>
              About Us
            </Typography>
            <Typography variant="h5" style={{ lineHeight: "1.5" }}>
              {AboutContent}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="center"
              sx={{ pt: 3, fontWeight: 700, color: "#25335C", mt: 2 }}
            >
              Our Mission
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <GoalCard
              title="Time Saving"
              description="Our app's primary goal is to help you save time when making dining decisions. We believe that dining decisions should be straightforward and hassle-free."
              icon={<AccessTimeFilledIcon style={{ fontSize: "60px", color: "#25335C" }} />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <GoalCard
              title="Reliability"
              description="We prioritize accuracy and trustworthiness in our recommendations. Instead of solely relying on GPT-3's knowledge from 2021, we fetch real-time data through the Google API to provide you with up-to-date and reliable information."
              icon={<VerifiedUserIcon style={{ fontSize: "60px", color: "#25335C" }} />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <GoalCard
              title="Social Connection"
              description="We believe food taste best when shared with your loved ones. Our app encourages you to share and discover new culinary adventures with others."
              icon={<PeopleAltIcon style={{ fontSize: "60px", color: "#25335C" }} />}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutSection;
