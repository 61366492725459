import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

const GoalCard = ({ title, description, icon }) => {
  return (
    <Card variant="outlined" sx={{ backgroundColor: "transparent", border: "none" }}>
      <CardContent>
        <Box display="flex" justifyContent="center">
          {icon}
        </Box>
        <Typography variant="h5" align="center" sx={{ fontWeight: 700, color: "#25335C", py: 1 }}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{
            color: "#25335C",
            fontWeight: 600,
            fontSize: "17px",
          }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default GoalCard;
