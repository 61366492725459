import React from "react";
import { AppBar, Toolbar, Typography, IconButton, Button, Stack } from "@mui/material";
import { Link as AnchorLink } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";
import AppLogo from "../../assets/logo.png";
import { useLogout } from "../../services/customHooks/useLogout";
import { useAuthContext } from "../../services/customHooks/useAuthContext";

const APP_NAME = "BiteQuest";

const appBarStyle = {
  backgroundColor: "white",
};

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();
  const { user } = useAuthContext();

  const handleClickLogo = () => {
    navigate("./");
  };

  const handleClickLogout = () => {
    logout();
  };

  const isHomePage = location.pathname === "/";

  return (
    <AppBar position="fixed" style={appBarStyle} elevation={4}>
      <Toolbar>
        <AnchorLink to="main-section" smooth={true} duration={500}>
          <IconButton
            size="large"
            onClick={handleClickLogo}
            style={{
              borderRadius: "0px",
            }}
          >
            <img src={AppLogo} alt="App Icon" width="40" />
            <Typography variant="h5" color="black" sx={{ fontWeight: "700" }}>
              {APP_NAME}
            </Typography>
          </IconButton>
        </AnchorLink>

        {/* main menu items */}
        {isHomePage && (
          <>
            <Stack direction="row" spacing={{ xs: 1, md: 2 }}>
              <Button>
                <AnchorLink to="main-section" smooth={true} duration={500}>
                  <Typography variant="h6">Home</Typography>
                </AnchorLink>
              </Button>
              <Button>
                <AnchorLink to="options-section" smooth={true} duration={500}>
                  <Typography variant="h6">Selections</Typography>
                </AnchorLink>
              </Button>
              <Button>
                <AnchorLink to="about-section" smooth={true} duration={500}>
                  <Typography variant="h6">About </Typography>
                </AnchorLink>
              </Button>
            </Stack>
          </>
        )}

        {/* display sign up & login OR logout based on auth */}
        {/* {!user ? (
          <Stack
            direction="row"
            spacing={{ xs: 1, md: 3 }}
            sx={{ ml: "auto", mr: { xs: 0, sm: 5, md: 10, l: 15, xl: 20 } }}
          >
            <Button variant="outlined" onClick={() => navigate("/signup")}>
              <Typography variant="h6">Sign up</Typography>
            </Button>
            <Button variant="outlined" onClick={() => navigate("/login")}>
              <Typography variant="h6">Login</Typography>
            </Button>
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={{ xs: 1, md: 3 }}
            sx={{ ml: "auto", mr: { xs: 0, sm: 5, md: 10, l: 15, xl: 20 } }}
          >
            <Button>
              <Typography variant="h6">{user.email}</Typography>
            </Button>
            <Button variant="outlined" onClick={handleClickLogout}>
              <Typography variant="h6">Logout</Typography>
            </Button>
          </Stack>
        )} */}
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
