import { gridItemStyle } from "./mainStyles";

const columnStyle = {
  ...gridItemStyle,
  minHeight: "300px",
};

const innerContainerStyle = {
  // flex: 1,
  paddingTop: "12px",
  borderRadius: "20px",
  // border: `1px solid ${colors.secondaryText}`,
};

const innerItemStyle = {
  paddingTop: "8x",
  paddingBottom: "8px",
};

export { columnStyle, innerContainerStyle, innerItemStyle };
