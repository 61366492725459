import React, { useState } from "react";
import { Card, CardActions, CardContent, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";

const secondaryTextColor = "#808080";

const commonStyle = {
  display: "flex",
  flexDirection: "column",
  borderWidth: "1px",
  width: "85%",
  borderRadius: "25px",
  maxWidth: "350px",
  margin: "auto",
  marginBottom: "10%",
  transition: "transform 0.3s",
};

const cardStyle = {
  ...commonStyle,
  border: "1px solid rgb(226, 232, 240)",
  background: "rgb(240, 247, 247)",
};

const hoveredCardStyle = {
  ...commonStyle,
  border: "1px solid rgba(255, 161, 22, 0.3)",
  background:
    "linear-gradient(294.57deg, rgba(255, 148, 88, 0.4) 0%, rgba(252, 229, 172, 0.4) 100%)",
  transform: "scale(1.15)",
};

const cardContentStyle = {
  height: "300px",
  padding: "24px",
};

const cardActionsStyle = {
  padding: "24px",
};

const OptionCard = ({ scenario }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleStartDecision = (url, enabled) => {
    if (enabled) {
      navigate(`/${url}`);
    } else {
      // TODO: show an alert
    }
  };

  const modeComponent = () => {
    if (scenario.mode === "live") {
      return null;
    }

    let style;
    if (scenario.mode === "deprecated") {
      style = { color: "brown", fontSize: "16px", fontStyle: "italic", fontWeight: "bold" };
    } else if (scenario.mode === "beta") {
      style = { color: "#03A9F4", fontSize: "16px", fontWeight: "bold" };
    }

    return (
      <Typography variant="h7" style={style}>
        ({scenario.mode})
      </Typography>
    );
  };

  return (
    <Card
      variant="elevation"
      elevation={isHovered ? 6 : 3}
      sx={isHovered ? hoveredCardStyle : cardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardContent style={cardContentStyle}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mr: 1 }}>
            {scenario.option}
          </Typography>
          {modeComponent()}
        </div>
        <Typography variant="body2" sx={{ color: secondaryTextColor, fontWeight: "bold", mb: 2 }}>
          {scenario.description}
        </Typography>

        {scenario.bullets.map((bullet, index) => (
          <Typography
            variant="body2"
            key={index}
            sx={{ display: "flex", alignItems: "center", mb: 1 }}
          >
            <EditIcon sx={{ color: "#F44336", fontSize: "18px", marginRight: "5px" }} />
            {bullet}
          </Typography>
        ))}

        {scenario.finalStep && (
          <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
            <DoneIcon
              sx={{
                color: "#4CAF50",
                fontSize: "18px",
                fontWeight: "bold",
                marginRight: "5px",
                stroke: "#4CAF50",
                strokeWidth: 2,
                alignItems: "center",
                mb: 1,
              }}
            />
            <Typography variant="body2" sx={{ fontWeight: "bold", mb: "1" }}>
              {scenario.finalStep}
            </Typography>
          </div>
        )}
        {/* tags */}
      </CardContent>

      <CardActions style={cardActionsStyle}>
        <Button
          variant="contained"
          color="primary"
          disabled={!scenario.enabled}
          onClick={() => handleStartDecision(scenario.url, scenario.enabled)}
          sx={{ width: "100%", fontWeight: "600" }}
        >
          Start
        </Button>
      </CardActions>
    </Card>
  );
};

export default OptionCard;
