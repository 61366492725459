import React from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import StartButton from "../../components/buttons/StartButton";
import {
  containerStyle,
  headingStyle,
  descriptionStyle,
  imageContainerStyle,
  imageStyle,
} from "../../styles/mainStyles.js";

import imageSource from "../../assets/picking_food_image.png";
import { backgroundStyle } from "../../styles/_defaults.js";

const mainValueProposition = (
  <Typography variant="h4" style={headingStyle}>
    Quick & Smart Dining Decisions
  </Typography>
);

const introductionText = (
  <Typography variant="body1" style={descriptionStyle}>
    Swiftly find the best dining spots. Our{" "}
    <span style={{ textDecoration: "underline" }}>AI Powered</span> app selects restaurants for you,
    so you can focus on enjoying your meal!
  </Typography>
);

const MainSection = () => {
  return (
    <div>
      <Box id="main-section" style={backgroundStyle}>
        <Container maxWidth={false} style={containerStyle}>
          <Grid container style={{ height: "100%", margin: "5px" }}>
            <Grid item xs={12} md={6}>
              {mainValueProposition}
              {introductionText}
              <StartButton />
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={imageContainerStyle}>
                <img src={imageSource} alt="App Screenshot" style={imageStyle} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default MainSection;
